import { useTranslation } from "react-i18next";

const TypeTranslator = () => {
  const { i18n, t } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const correspondenceStatus: any = [];
  correspondenceStatus[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  correspondenceStatus[2] = {
    id: 2,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  correspondenceStatus[3] = {
    id: 3,
    en_value: "Reviewed",
    ar_value: "تمت المراجعة",
  };
  correspondenceStatus[4] = { id: 4, en_value: "Approved", ar_value: "مكتمل" };
  correspondenceStatus[5] = { id: 5, en_value: "Rejected", ar_value: "مرفوض" };
  correspondenceStatus[6] = { id: 6, en_value: "Recall", ar_value: "استعادة" };
  correspondenceStatus[7] = {
    id: 7,
    en_value: "Delivered",
    ar_value: "تم التسليم",
  };

  const getCorrespondenceStatusValue = (value: number) => {
    const status = correspondenceStatus[value];

    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const appointmentTypes = [
    { text: t("Visitor"), value: 1 },
    { text: t("Company"), value: 2 },
  ];

  const getAppointmentTypeValue = (value: number) => {
    const appointmentType = appointmentTypes.find((x) => x.value === value);
    return appointmentType ? appointmentType.text : value;
  };

  const idTypes = [
    { text: t("National ID"), value: 1 },
    { text: t("Passport"), value: 2 },
  ];
  const getIdTypeValue = (value: number) => {
    const idType = idTypes.find((x) => x.value === value);
    return idType ? idType.text : value;
  };
  // for dropdowns
  const visitTypes = [
    { text: t("Civil"), value: 1 },
    { text: t("Soldier"), value: 2 },
  ];

  const getVisitTypeValue = (value: number) => {
    const visitType = visitTypes.find((x) => x.value === value);
    return visitType ? visitType.text : value;
  };

  const visitPurposes = [
    { text: t("Personal"), value: 1 },
    { text: t("Work"), value: 2 },
  ];

  const getVisitPurposeValue = (value: number) => {
    const visitPurpose = visitPurposes.find((x) => x.value === value);
    return visitPurpose ? visitPurpose.text : value;
  };

  const durations = [
    { text: t("Days"), value: 1 },
    { text: t("Week"), value: 2 },
  ];
  const getDurationValue = (value: number) => {
    const duration = durations.find((x) => x.value === value);
    return duration ? duration.text : value;
  };

  const vehicleTypes = [
    { text: t("Car"), value: 1 },
    { text: t("Truck"), value: 2 },
    { text: t("Van"), value: 3 },
    { text: t("Motorcycle"), value: 4 },
    { text: t("Delivery Truck"), value: 5 },
  ];

  const getVehicleTypeValue = (value: number) => {
    const vehicleType = vehicleTypes.find((x) => x.value === value);
    return vehicleType ? vehicleType.text : value;
  };

  const contactTypes = [
    { text: t("Mobile"), value: 1 },
    { text: t("Telephone"), value: 2 },
  ];

  const appointmentStatus: any = [];
  appointmentStatus[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  appointmentStatus[2] = {
    id: 2,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  appointmentStatus[3] = { id: 3, en_value: "Approved", ar_value: "مكتمل" };
  appointmentStatus[4] = { id: 4, en_value: "Rejected", ar_value: "مرفوض" };

  const getAppointmentStatusValue = (value: number) => {
    const status = appointmentStatus[value];

    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const getAppointmentStatusChipColor = (status: number) => {
    switch (status) {
      case 1:
        return "info";
      case 2:
        return "warning";
      case 3:
        return "success";
      case 4:
        return "error";
    }
  };

  const documentTemplateTypes: any = [];
  documentTemplateTypes[1] = {
    id: 1,
    en_value: t("Correspondence"),
    ar_value: t("Correspondence"),
  };
  documentTemplateTypes[2] = {
    id: 2,
    en_value: t("Delivery Note"),
    ar_value: t("Delivery Note"),
  };

  const getDocumentTemplateTypeValue = (value: number) => {
    const type = documentTemplateTypes[value];

    if (type) {
      if (languageIsEn) return type.en_value;
      else return type.ar_value;
    } else return value;
  };

  return {
    getCorrespondenceStatusValue,
    appointmentTypes,
    getAppointmentTypeValue,
    idTypes,
    getIdTypeValue,
    visitTypes,
    getVisitTypeValue,
    visitPurposes,
    getVisitPurposeValue,
    durations,
    getDurationValue,
    vehicleTypes,
    getVehicleTypeValue,
    contactTypes,
    getAppointmentStatusValue,
    getAppointmentStatusChipColor,
    documentTemplateTypes,
    getDocumentTemplateTypeValue,
  };
};

export default TypeTranslator;
