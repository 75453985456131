import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import DocumentTemplateTab from "./documenttemplatetab";

import CardDX from "../../../components/layout/carddx";
import GridDX from "../../../components/layout/griddx";
import Loading from "../../../components/loading";
import TextFieldDX from "../../../components/controls/textfielddx";
import ButtonDX from "../../../components/controls/buttondx";
import CKEditorDX from "../../../components/controls/ckeditordx";
import SelectListDX from "../../../components/controls/selectlistdx";

import useDocumentTemplateService from "../../../shared/services/documenttemplateservice";

import { useNotificationContext } from "../../../context/notificationcontext";
import TypeTranslator from "../../../shared/typetranslator";

const DocumentTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { documentTemplateTypes } = TypeTranslator();
  const languageIsEn = i18n.language === "en";

  const [open, setOpen] = useState(false);
  const {
    getDocumentTemplateByID,
    updateDocumentTemplate,
    addDocumentTemplate,
  } = useDocumentTemplateService();
  const id = location.state.id;
  const defaultValues = {
    enName: "",
    arName: "",
    content: "",
    documentTemplateTypeId: 1,
  };

  const [templateData, setTemplateData] = useState<any>(defaultValues);
  const [template, setTemplate] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [edit, setEdit] = useState(false);
  const [contentLang, setContentLang] = useState(
    i18n.language === "en" ? "en" : "ar"
  );

  const [refetchStatusData, setRefetchStatusData] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (id) getDetails();
    else setEdit(true);
  }, []);

  const getDetails = () => {
    setIsLoading(true);
    getDocumentTemplateByID(Number(id))
      .then((res) => {
        setTemplateData(res);
        setTemplate(res);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setTemplateData({
      ...templateData,
      [name]: value,
    });
  };

  const templateDataChange = (newContent: string) => {
    setIsChanged(true);

    setTemplateData((oldValue: any) => {
      return {
        ...oldValue,
        content: newContent,
      };
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (templateData.enName.length === 0)
      newErrors["enName"] = t("This field is required");
    if (templateData.arName.length === 0)
      newErrors["arName"] = t("This field is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      id
        ? updateDocumentTemplate(templateData)
            .then(() => {
              setInfo(t("Document template updated successfully"));
              setIsChanged(false);
              setEdit(false);
              setRefetchStatusData(!refetchStatusData);
              getDetails();
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false))
        : addDocumentTemplate(templateData)
            .then((res) => {
              setInfo(t("Document template created successfully"));
              navigate("/cts/templates");
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false));
    }
  };

  return (
    <GridDX container flexDirection="column" sx={{ pt: 1 }}>
      {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
      <CardDX>
        <CardContent>
          <GridDX container sx={{ mb: 2 }}>
            <GridDX item xs={8}>
              <Typography fontSize={22}>
                <InfoOutlinedIcon fontSize="medium" /> {t("Document Template")}
              </Typography>
            </GridDX>
            <GridDX item xs={2}>
              {id && edit && (
                <ButtonDX
                  onClick={() => {
                    setTemplateData(template);
                    setEdit(false);
                  }}
                >
                  {t("Discard")}
                </ButtonDX>
              )}
            </GridDX>
            <GridDX item xs={2}>
              {edit ? (
                <ButtonDX disabled={!isChanged} onClick={onSave}>
                  {t("Save")}
                </ButtonDX>
              ) : (
                <ButtonDX onClick={() => setEdit(true)}>{t("Edit")}</ButtonDX>
              )}
            </GridDX>
          </GridDX>

          <Divider sx={{ mb: 1 }} />
          <GridDX fullWidth>
            <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
              <GridDX item xs={6}>
                <TextFieldDX
                  readOnly={!edit}
                  label={t("English Name")}
                  name="enName"
                  value={templateData.enName}
                  placeholder={t("No Information")}
                  onChange={handleInputChange}
                  error={errors["enName"]}
                />
              </GridDX>
              <GridDX item xs={6}>
                <TextFieldDX
                  readOnly={!edit}
                  label={t("Arabic Name")}
                  name="arName"
                  value={templateData.arName}
                  placeholder={t("No Information")}
                  onChange={handleInputChange}
                  error={errors["arName"]}
                />
              </GridDX>
              <GridDX item xs={6}>
                <SelectListDX
                  disabled={!edit}
                  label={t("Type")}
                  name="documentTemplateTypeId"
                  value={templateData.documentTemplateTypeId}
                  items={documentTemplateTypes?.map((t: any) => {
                    return {
                      value: t.id,
                      text: languageIsEn ? t.en_value : t.ar_value,
                    };
                  })}
                  onChange={handleInputChange}
                  error={errors["documentTemplateTypeId"]}
                />
              </GridDX>
              <GridDX item xs={12}>
                <CKEditorDX
                  label={t("Template")}
                  langSelectorLabe={"Language"}
                  data={templateData?.content}
                  contentLang={contentLang}
                  setContentLang={setContentLang}
                  onChange={templateDataChange}
                  readOnly={!edit}
                />
              </GridDX>
            </GridDX>
          </GridDX>

          <GridDX fullWidth>{/* Text editor */}</GridDX>
        </CardContent>
      </CardDX>

      {id && (
        <GridDX item xs={12} justifyContent="start">
          <CardDX sx={{ mt: 2, width: "100%" }}>
            <CardContent>
              <DocumentTemplateTab
                getDetails={getDetails}
                correspondenceId={parseInt(id as string)}
                refetchStatusData={refetchStatusData}
              />
            </CardContent>
          </CardDX>
        </GridDX>
      )}
    </GridDX>
  );
};

export default DocumentTemplate;
