import { Typography, Chip, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../context/notificationcontext";
import TextFieldDX from "../../controls/textfielddx";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import AddEditModalDX from "../../business/addeditmodaldx";
import useDocumentService from "../../../shared/services/documentservice";
import SelectListDX from "../../controls/selectlistdx";
import useDocumentTypeService from "../../../shared/services/documenttypeservice";
import Loading from "../../loading";
import { getContentFromPdf } from "../../../shared/pdfoperations";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import useDocumentCategoryTypeService from "../../../shared/services/documentcategory";
import useDocumentProfileService from "../../../shared/services/documentprofileservice";
import { handleDateChange, singleImageOCR } from "../../../shared/globals";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import moment, { Moment } from "moment";
import DatePickerDX from "../../controls/datepickerdx";
import { useAuthContext } from "../../../context/authcontext";

const DocumentUploadModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const { bulkAddDocument, updateDocument, getDocumentByID, addDocument } =
    useDocumentService();
  const { getDocumentTypes } = useDocumentTypeService();
  const { getDocumentCategoryTypes } = useDocumentCategoryTypeService();
  const { getDocumentProfiles, getDocumentProfileByID } =
    useDocumentProfileService();
  const { setError, setInfo } = useNotificationContext();
  const defaultValues = {
    enName: "",
    arName: "",
    documentTypeId: "",
    documentCategories: [],
    documentProfileInDocuments: [],
    // file: null as null | File,
    files: [] as File[],
    profileData: null,
    expirationDate: null as null | Moment,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [data, setData] = useState(defaultValues);
  const [docTypes, setDocTypes] = useState<any>([]);
  const [docCategories, setDocCategories] = useState<any>([]);
  const [docProfiles, setDocProfiles] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [profileErrors, setProfileErrors] = useState<any>({});
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  useEffect(() => {
    populateLists();
  }, []);

  useEffect(() => {
    if (props.data) {
      getDocumentByID(props.data.documentId).then((response) => {
        const documentData = {
          ...response,
          documentCategories: response.documentCategories.map((type: any) => {
            return {
              value: type.documentCategoryTypeId,
              text: languageIsEn ? type.categoryEnName : type.categoryArName,
            };
          }),
          documentProfileInDocuments: response.documentProfileInDocuments.map(
            (data: any) => {
              return {
                value: data.documentProfileId,
                text: languageIsEn ? data.profileEnName : data.profileArName,
              };
            }
          ),
        };
        //appending a dummy number in files array to avoid unnecessary errors
        setDataFromApi({ ...response, files: [1] });
        setData({ ...documentData, files: [1] });
      });
    }
  }, [props.data]);

  const populateLists = async () => {
    const p1 = getDocumentTypes();
    const p2 = getDocumentCategoryTypes();
    const p3 = getDocumentProfiles();
    setIsLoading(true);
    Promise.all([
      p1.then((types) => setDocTypes(types)),
      p2.then((categories) => setDocCategories(categories)),
      p3.then((profiles) => setDocProfiles(profiles)),
    ])
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    // first check if there are more than one files then no need to check for enName and arName
    if (!(data.files.length > 1)) {
      if (data.enName.length === 0)
        newErrors["enName"] = t("This field cannot be empty");
      if (data.arName.length === 0)
        newErrors["arName"] = t("This field cannot be empty");
    }
    // if (data.file === null) {
    //   newErrors["file"] = t("This field cannot be empty");
    //   setError(t("File is required"));
    // }
    if (data.files.length === 0) {
      newErrors["files"] = t("This field cannot be empty");
      setError(t("File is required"));
    }
    // if (!data.expirationDate) {
    //   newErrors["expirationDate"] = t("This field cannot be empty");
    // }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const validateProfiles = (profileData: any) => {
    const newErrors: any = {};

    for (let i in selectedDocumentProfiles) {
      const profile = selectedDocumentProfiles[i];
      for (let j in profile.documentProfileFields) {
        const field = profile.documentProfileFields[j];

        if (
          field?.isMandatory &&
          !profileData[`${profile.documentProfileId}`][`${field.enName}`]
        )
          newErrors[`${field.documentProfileFieldId}`] = t(
            "This field cannot be empty"
          );
      }
    }

    setProfileErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const toSave = () => {
    if (validateForm()) {
      setIsSaving(true);
      let categories = data.documentCategories.map((category: any) => {
        let categoryData = {
          documentCategoryId: 0,
          documentCategoryTypeId: category.value,
        };
        if (dataFromApi && dataFromApi?.documentCategories) {
          let categoryDataFromApi = dataFromApi?.documentCategories?.find(
            (categoryFromApi: any) =>
              categoryFromApi.documentCategoryTypeId === category.value &&
              categoryFromApi.isDeleted != true
          );
          if (categoryDataFromApi) {
            categoryData.documentCategoryId =
              categoryDataFromApi.documentCategoryId;
          }
        }
        return categoryData;
      });
      let categoriesFromApi = dataFromApi?.documentCategories?.map(
        (category: any) => {
          const { categoryArName, categoryEnName, documentId, ...rest } =
            category;
          return {
            ...rest,
          };
        }
      );
      if (categoriesFromApi) {
        categories = [...categories, ...categoriesFromApi];
      }
      // remove duplicate categories from the array based on documentCategoryTypeId and documentCategoryId and isDeleted
      categories = categories.filter(
        (category: any, index: number, self: any) =>
          index ===
          self.findIndex(
            (t: any) =>
              t.documentCategoryTypeId === category.documentCategoryTypeId &&
              t.documentCategoryId === category.documentCategoryId &&
              t.isDeleted === category.isDeleted
          )
      );

      // for profiles
      let profiles = data.documentProfileInDocuments.map((profile: any) => {
        let profileData = {
          documentProfileInDocumentId: 0,
          documentProfileId: profile.value,
        };
        if (dataFromApi && dataFromApi?.documentProfileInDocuments) {
          let profileDataFromApi =
            dataFromApi?.documentProfileInDocuments?.find(
              (profileFromApi: any) =>
                profileFromApi.documentProfileId === profile.value &&
                profileFromApi.isDeleted != true
            );
          if (profileDataFromApi) {
            profileData.documentProfileInDocumentId =
              profileDataFromApi.documentProfileInDocumentId;
          }
        }
        return profileData;
      });
      let profilesFromApi = dataFromApi?.documentProfileInDocuments?.map(
        (profile: any) => {
          const { profileArName, profileEnName, documentId, ...rest } = profile;
          return {
            ...rest,
          };
        }
      );
      if (profilesFromApi) {
        profiles = [...profiles, ...profilesFromApi];
      }
      // remove duplicate profiles from the array based on documentProfileTypeId and documentProfileId and isDeleted
      profiles = profiles.filter(
        (profile: any, index: number, self: any) =>
          index ===
          self.findIndex(
            (t: any) =>
              t.documentProfileId === profile.documentProfileId &&
              t.documentProfileInDocumentId ===
                profile.documentProfileInDocumentId &&
              t.isDeleted === profile.isDeleted
          )
      );

      // removed the object from profileData that is not present in selected profiles
      let profileData = data.profileData;
      let selectedProfiles = data?.documentProfileInDocuments?.map(
        (profile: any) => {
          return profile.value;
        }
      );
      let newProfileData: any = {};
      // match the keys of profile data with the the selected profiles array of keys
      selectedProfiles.forEach((profile: any) => {
        // @ts-ignore
        if (profileData[profile]) {
          // @ts-ignore
          newProfileData[profile] = profileData[profile];
        }
      });

      if (!validateProfiles(newProfileData)) {
        setIsSaving(false);
        setError(t("A mandatory profile field is missing"));
        return;
      }

      let updatedData = {
        ...data,
        documentCategories: categories,
        documentProfileInDocuments: profiles,
        profileData: newProfileData,
      };

      if (props.data) {
        updateDocument(updatedData)
          .then(() => {
            setInfo(t("Document updated successfully"));
            props.handleClose(true);
          })
          .catch((err) => setError(err))
          .finally(() => setIsSaving(false));
      } else {
        const files = data.files as File[];
        const isSingleFile = files.length === 1;

        const processSingleFile = async (file: File) => {
          const formData = new FormData();
          const fileType = file.type.split("/");

          formData.append("File", file);
          formData.append("EnName", data.enName);
          formData.append("ArName", data.arName);
          formData.append("DocumentTypeId", data.documentTypeId);
          formData.append("FolderId", props.folderId);

          if (data?.expirationDate) {
            formData.append(
              "ExpirationDate",
              moment(data.expirationDate).toISOString() || ""
            );
          } else {
            formData.append("ExpirationDate", " ");
          }

          profiles.forEach((profile, index) => {
            formData.append(
              `DocumentProfileInDocuments[${index}]`,
              profile.documentProfileId
            );
          });

          categories.forEach((category, index) => {
            formData.append(
              `DocumentCategories[${index}]`,
              category.documentCategoryTypeId
            );
          });

          Object.keys(newProfileData).forEach((profileId) => {
            const profileFields = newProfileData[profileId];
            Object.keys(profileFields).forEach((fieldName) => {
              formData.append(
                `ProfileData[${profileId}][${fieldName}]`,
                profileFields[fieldName]
              );
            });
          });

          return formData;
        };

        const processBulkFiles = async () => {
          const formData = new FormData();
          const processedFiles: Promise<void>[] = files.map(
            async (file, index) => {
              const fileType = file.type.split("/");
              formData.append(`data[${index}].File`, file);

              // Append metadata and additional data
              formData.append(`data[${index}][FolderId]`, props.folderId);
              formData.append(
                `data[${index}][EnName]`,
                file.name.split(".")[0]
              );
              formData.append(
                `data[${index}][ArName]`,
                file.name.split(".")[0]
              );
              formData.append(
                `data[${index}][DocumentTypeId]`,
                data.documentTypeId
              );

              categories.forEach((category, catIndex) => {
                formData.append(
                  `data[${index}].DocumentCategories[${catIndex}]`,
                  category.documentCategoryTypeId
                );
              });

              profiles.forEach((profile, profIndex) => {
                formData.append(
                  `data[${index}].DocumentProfileInDocuments[${profIndex}]`,
                  profile.documentProfileId
                );
              });

              Object.keys(newProfileData).forEach((profileId) => {
                const profileFields = newProfileData[profileId];
                Object.keys(profileFields).forEach((fieldName) => {
                  formData.append(
                    `data[${index}].ProfileData[${profileId}][${fieldName}]`,
                    profileFields[fieldName]
                  );
                });
              });

              if (data?.expirationDate) {
                formData.append(
                  `data[${index}][ExpirationDate]`,
                  moment(data.expirationDate).toISOString() || ""
                );
              } else {
                formData.append(`data[${index}][ExpirationDate]`, " ");
              }
            }
          );

          // Wait for all files to be processed
          await Promise.all(processedFiles);

          return formData;
        };

        if (isSingleFile) {
          const singleFile = files[0];
          processSingleFile(singleFile)
            .then((formData) => {
              addDocument(formData)
                .then(() => {
                  setInfo(t("Document added successfully"));
                  props.handleClose(true);
                })
                .catch((err) => setError(err))
                .finally(() => setIsSaving(false));
            })
            .catch((err) => {
              setError(err);
              setIsSaving(false);
            });
        } else {
          processBulkFiles()
            .then((formData) => {
              bulkAddDocument(formData)
                .then(() => {
                  setInfo(t("Documents added successfully"));
                  props.handleClose(true);
                })
                .catch((err) => setError(err))
                .finally(() => setIsSaving(false));
            })
            .catch((err) => {
              setError(err);
              setIsSaving(false);
            });
        }
      }
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length == 1) {
      setIsChanged(true);
      setData({
        ...data,
        enName:
          data.files.length > 0
            ? ""
            : data.enName === ""
            ? acceptedFiles[0].name.split(".")[0]
            : data.enName,
        arName: data.files.length > 0 ? "" : data.arName,
        files: [...data.files, ...acceptedFiles],
      });
    } else if (acceptedFiles.length > 0) {
      setIsChanged(true);
      setData({
        ...data,
        enName: "",
        arName: "",
        files: [...data.files, ...acceptedFiles],
      });
    }
  };

  const fileTypeValidator = (file: File) => {
    if (file.type === "" || file.type === "application/octet-stream") {
      setError(t("File type not accepted"));
      return {
        code: "wrong file type",
        message: `File type not accepted`,
      };
    } else return null;
  };

  // for categories
  useEffect(() => {
    if (dataFromApi.documentCategories) {
      // check if a category was present in dataFromApi but not in data then mark it as deleted in data from API
      // just set the flag isDeleted = true  in those specific categories in actual dataFromApi state directly
      let deletedCategories = dataFromApi.documentCategories.filter(
        (category: any) =>
          !data.documentCategories.some(
            (item: any) => item.value === category.documentCategoryTypeId
          )
      );
      deletedCategories = deletedCategories.map((category: any) => {
        return { ...category, isDeleted: true };
      });
      let mergedCategories = dataFromApi.documentCategories.map(
        (category: any) => {
          // Check if category exists in deletedCategories
          let deletedCategory = deletedCategories.find(
            (deletedCat: any) =>
              deletedCat.documentCategoryId === category.documentCategoryId
          );
          if (deletedCategory) {
            // Merge the category with the deletedCategory and return
            return { ...category, isDeleted: true };
          } else {
            return category;
          }
        }
      );
      const updatedDataFromApi = {
        ...dataFromApi,
        documentCategories: mergedCategories,
      };
      setDataFromApi(updatedDataFromApi);
    }
  }, [data.documentCategories.length]);

  // for profile
  useEffect(() => {
    if (dataFromApi.documentProfileInDocuments) {
      // check if a profile was present in dataFromApi but not in data then mark it as deleted in data from API
      // just set the flag isDeleted = true  in those specific profiles in actual dataFromApi state directly
      let deletedProfiles = dataFromApi.documentProfileInDocuments.filter(
        (profile: any) =>
          !data.documentProfileInDocuments.some(
            (item: any) => item.value === profile.documentProfileId
          )
      );
      deletedProfiles = deletedProfiles.map((profile: any) => {
        return { ...profile, isDeleted: true };
      });
      let mergedProfiles = dataFromApi.documentProfileInDocuments.map(
        (profile: any) => {
          // Check if profile exists in deletedProfiles
          let deletedProfile = deletedProfiles.find(
            (deletedProf: any) =>
              deletedProf.documentProfileId === profile.documentProfileId
          );
          if (deletedProfile) {
            // Merge the profile with the deletedProfile and return
            return { ...profile, isDeleted: true };
          } else {
            return profile;
          }
        }
      );
      const updatedDataFromApi = {
        ...dataFromApi,
        documentProfileInDocuments: mergedProfiles,
      };
      setDataFromApi(updatedDataFromApi);
    }
  }, [data.documentProfileInDocuments.length]);

  const [tab, setTab] = useState("0");

  const handleTabChange = (event: any, newValue: any) => setTab(newValue);
  const [selectedDocumentProfiles, setSelectedDocumentProfiles] = useState<any>(
    []
  );

  useEffect(() => {
    //check if the selected profile contains a profile that is not present in data?.documentProfileInDocuments then remove it from selectedDocumentProfiles as well
    let selectedProfiles = data?.documentProfileInDocuments.map(
      (profile: any) => {
        return profile.value;
      }
    );
    let newSelectedProfiles = selectedDocumentProfiles.filter((profile: any) =>
      selectedProfiles.includes(profile.documentProfileId)
    );
    setSelectedDocumentProfiles(newSelectedProfiles);

    data?.documentProfileInDocuments?.forEach((profile: any) => {
      // call the api to get the profile data only if the profile is not already present in the selectedDocumentProfiles
      if (
        !selectedDocumentProfiles.some(
          (selectedProfile: any) =>
            selectedProfile.documentProfileId === profile.value
        )
      ) {
        getDocumentProfileByID(profile.value).then((response) => {
          setSelectedDocumentProfiles((prev: any) => [...prev, response]);
        });
      }
    });
  }, [data?.documentProfileInDocuments.length]);

  useEffect(() => {
    if (selectedDocumentProfiles?.length > 0) {
      let profileData = data.profileData || {};
      selectedDocumentProfiles.forEach((profile: any) => {
        // check if the profile is already present in the profileData if not then add it
        //@ts-ignore
        if (!profileData[profile.documentProfileId]) {
          let profileObj = {};
          profile?.documentProfileFields?.forEach((field: any) => {
            //@ts-ignore
            profileObj[field.enName] = ""; // Set whatever value you need here
          });
          //@ts-ignore
          profileData[profile.documentProfileId] = profileObj;
        }
      });

      setData((prev: any) => ({
        ...prev,
        profileData: profileData,
      }));
    }
  }, [selectedDocumentProfiles]);
  return (
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={props.data ? t("Edit Document") : t("Upload Document")}
      maxWidth="md"
      onSaveClick={toSave}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX
        container
        sx={{ width: "100%", height: "100%", my: 1 }}
        alignItems="center"
        columnSpacing={1}
        rowSpacing={2}
      >
        {!props.data && (
          <GridDX item xs={12} flexDirection="column">
            <Dropzone
              validator={fileTypeValidator}
              onDrop={onDrop}
              // noClick
              // noKeyboard
            >
              {({ getRootProps, getInputProps }) => (
                <BoxDX
                  {...getRootProps({ className: "dropzone" })}
                  sx={{
                    width: "100%",
                    height: 100,
                    border: errors["files"]
                      ? "1px dashed red"
                      : "1px dashed grey",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <GridDX item xs={12} justifyContent="center">
                    <input {...getInputProps()} />
                  </GridDX>
                  <GridDX
                    item
                    xs={12}
                    height={100}
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography
                      sx={{
                        fontSize: 18,
                        textAlign: "center",
                      }}
                    >
                      {t("Drag and drop files here, or click to select files")}
                    </Typography>
                  </GridDX>
                </BoxDX>
              )}
            </Dropzone>
            <GridDX container spacing={1} sx={{ mt: 2 }}>
              {/* Display Uploaded Files */}
              {(data?.files || []).map((file, index) => (
                <Chip
                  key={index}
                  color="primary"
                  label={file.webkitRelativePath || file.name}
                  onDelete={() => {
                    setData((prevData) => {
                      if (prevData.files.length === 1) {
                        return {
                          ...prevData,
                          enName: "",
                          arName: "",
                          files: [],
                        };
                      }
                      return {
                        ...prevData,
                        files: prevData.files.filter((_, i) => i !== index),
                      };
                    });
                  }}
                  sx={{ mt: 1, mx: 1, minWidth: 150 }}
                />
              ))}
            </GridDX>
          </GridDX>
        )}
        {!(data?.files?.length > 1) && (
          <>
            <GridDX item xs={12}>
              <TextFieldDX
                label={t("English Name")}
                name="enName"
                value={data.enName}
                onChange={handleInputChange}
                error={errors["enName"]}
              />
            </GridDX>
            <GridDX item xs={12}>
              <TextFieldDX
                label={t("Arabic Name")}
                name="arName"
                value={data.arName}
                onChange={handleInputChange}
                error={errors["arName"]}
              />
            </GridDX>
          </>
        )}
        <GridDX item xs={12}>
          <DatePickerDX
            label={t("Expiration Date")}
            sx={{ flex: 1 }}
            name="expirationDate"
            value={moment(data?.expirationDate)}
            calendarId={calendarId}
            onChange={(value: any) => {
              handleDateChange({
                value: value,
                name: "expirationDate",
                setIsChanged: setIsChanged,
                setData: setData,
                data: data,
              });
            }}
            error={errors["expirationDate"]}
            minDate={moment()}
          />
        </GridDX>
        <GridDX item xs={12}>
          <SelectListDX
            label={t("Document Type")}
            items={docTypes.map((type: any) => {
              return {
                value: type.documentTypeId,
                text: languageIsEn ? type.enName : type.arName,
              };
            })}
            name="documentTypeId"
            value={data.documentTypeId}
            onChange={handleInputChange}
            disabled={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Document Categories")}
            name="categories"
            value={data.documentCategories}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setData({
                ...data,
                documentCategories: newValue,
              });
            }}
            list={docCategories.map((type: any) => {
              return {
                value: type.documentCategoryTypeId,
                text: languageIsEn ? type.enName : type.arName,
              };
            })}
            disabled={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Document Profiles")}
            name="documentProfileInDocuments"
            value={data.documentProfileInDocuments}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setData({
                ...data,
                documentProfileInDocuments: newValue,
              });
              //smooth scroll to bottom
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
            }}
            list={docProfiles.map((type: any) => {
              return {
                value: type.documentProfileId,
                text: languageIsEn ? type.enName : type.arName,
              };
            })}
            disabled={isLoading}
          />
        </GridDX>

        {/* tabs for each profile selected */}
        {selectedDocumentProfiles?.length > 0 && (
          <GridDX item xs={12} sx={{ color: "black", flexWrap: "wrap" }}>
            <TabContext value={tab}>
              <BoxDX
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                }}
              >
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  {selectedDocumentProfiles.map(
                    (profile: any, index: number) => (
                      <Tab
                        key={index}
                        label={
                          i18n.language === "en"
                            ? profile.enName
                            : profile.arName
                        }
                        value={index.toString()}
                      />
                    )
                  )}
                </TabList>
              </BoxDX>
              <BoxDX sx={{ width: "100%" }}>
                {selectedDocumentProfiles?.map(
                  (profile: any, index: number) => (
                    <TabPanel key={index} value={index.toString()}>
                      <GridDX
                        container
                        rowSpacing={1}
                        columnSpacing={2}
                        sx={{ padding: 0 }}
                      >
                        {profile?.documentProfileFields?.map(
                          (field: any, index: number) => (
                            <GridDX item key={index} xs={6}>
                              {field.fieldDataType === 1 ? (
                                <DatePickerDX
                                  label={
                                    i18n.language === "en"
                                      ? field.enName
                                      : field.arName
                                  }
                                  name={field.enName}
                                  sx={{ flex: 1 }}
                                  value={moment(
                                    data.profileData?.[
                                      profile.documentProfileId
                                    ]?.[field.enName] || ""
                                  )}
                                  calendarId={calendarId}
                                  error={
                                    profileErrors[
                                      `${field.documentProfileFieldId}`
                                    ]
                                  }
                                  onChange={(value: any) => {
                                    setIsChanged(true);
                                    setData((prev: any) => {
                                      let newProfileData = {
                                        ...prev.profileData,
                                      };
                                      newProfileData[profile.documentProfileId][
                                        field.enName
                                      ] = value;
                                      return {
                                        ...prev,
                                        profileData: newProfileData,
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <TextFieldDX
                                  label={
                                    i18n.language === "en"
                                      ? field.enName
                                      : field.arName
                                  }
                                  name={field.enName}
                                  // profileData should be used here
                                  value={
                                    data.profileData?.[
                                      profile.documentProfileId
                                    ]?.[field.enName] || ""
                                  }
                                  error={
                                    profileErrors[
                                      `${field.documentProfileFieldId}`
                                    ]
                                  }
                                  onChange={(e: any) => {
                                    setIsChanged(true);
                                    setData((prev: any) => {
                                      let newProfileData = {
                                        ...prev.profileData,
                                      };
                                      newProfileData[profile.documentProfileId][
                                        field.enName
                                      ] = e.target.value;
                                      return {
                                        ...prev,
                                        profileData: newProfileData,
                                      };
                                    });
                                  }}
                                />
                              )}
                            </GridDX>
                          )
                        )}
                      </GridDX>
                    </TabPanel>
                  )
                )}
              </BoxDX>
            </TabContext>
          </GridDX>
        )}
      </GridDX>
    </AddEditModalDX>
  );
};

export default DocumentUploadModal;
