import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import momentHijri from "moment-hijri";
import { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";

import {
  APIDateFormatter,
  CorrespondenceStatus,
  handleInputChange,
  sortListAlphabetically,
  textToBase64Barcode,
} from "../../../shared/globals";
import {
  addImageToPDF,
  addTextToPDF,
  getContentFromPdf,
} from "../../../shared/pdfoperations";
import useCorrespondenceService from "../../../shared/services/correspondenceservice";
import useCorrespondenceTypeService from "../../../shared/services/correspondencetypes";
import useDeliveryService from "../../../shared/services/deliverytypeservice";
import useDocumentTemplateService from "../../../shared/services/documenttemplateservice";
import useDomainTypeService from "../../../shared/services/domaintypeservice";
import useImportanceLevelsService from "../../../shared/services/importancelevelsservice";
import usePrivacyLevelsService from "../../../shared/services/privacylevelsservice";
import useRoutingGroupService from "../../../shared/services/routinggroupservice";
import useUserService from "../../../shared/services/userservices";
import AutoCompleteListDX from "../../controls/autocompletelistdx";
import ButtonDX from "../../controls/buttondx";
import CheckBoxDX from "../../controls/checkboxdx";
import CKEditorDX from "../../controls/ckeditordx";
import ConfirmModal from "../../controls/confirmmodal";
import DatePickerDX from "../../controls/datepickerdx";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import ScanModal from "../scanmodal";
import useEntityService from "../../../shared/services/entityservice";

const CreateCorrespondenceModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const languageIsEn = i18n.language === "en";
  const { getUserDetails } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const {
    addCorrespondence,
    addCorrespondenceWithContent,
    getCorrespondenceList,
  } = useCorrespondenceService();
  const { getCorrespondenceType } = useCorrespondenceTypeService();
  const { getImportanceLevels } = useImportanceLevelsService();
  const { getPrivacyLevels } = usePrivacyLevelsService();
  const { getDomainTypes } = useDomainTypeService();
  const { getActiveUsers } = useUserService();
  const { getDeliveryTypes } = useDeliveryService();
  const { getRoutingGroups } = useRoutingGroupService();
  const { getDocumentTemplates } = useDocumentTemplateService();
  const { getEntity } = useEntityService();

  const defaultValues = {
    Subject: "",
    CorrespondenceDate: calendarId === 1 ? moment() : momentHijri(),
    DueDate: null,
    Description: "",
    Notes: "",
    CorrespondenceTypeId: 1,
    PreviousCorrespondenceId: null,
    ContentAsText: " ",
    MainAttachmentFile: "",
    ImportanceLevelId: null,
    PrivacyLevelId: null,
    DomainTypeId: null,
    RoutingGroupId: null,
    RoutedBy: "",
    applyWatermark: false,
    IsDocumentDelivered: false,
    DeliveryMethodId: null,
    EntityId: null,
    EntityContactPerson: "",
    ReferenceNumber: "",
    DateOfReference: null,
    DeliveryNoteTemplateId: null,
  };
  const [correspondenceData, setCorrespondenceData] =
    useState<any>(defaultValues);
  const [date, setDate] = useState(correspondenceData.CorrespondenceDate);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [extractingText, setExtractingText] = useState(false);
  const [open, setOpen] = useState(false);
  const [openScan, setOpenScan] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [attachmentType, setAttachmentType] = useState(1);
  const [correspondenceList, setCorrespondenceList] = useState([]);
  const [correspondenceTypeList, setCorrespondenceTypeList] = useState([]);
  const [importanceLevelList, setImportanceLevelList] = useState([]);
  const [privacyLevelList, setPrivacyLevelList] = useState([]);
  const [domainTypeList, setDomainTypeList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [routingGroups, setRoutingGroups] = useState<any>([]);
  const [routingGroupList, setRoutingGroupList] = useState<any>([]);
  const [filteredRoutingGroupsList, setFilteredRoutingGroupsList] = useState(
    []
  );
  const [documentTemplateList, setDocumentTemplateList] = useState([]);
  const [deliveryNoteList, setDeliveryNoteList] = useState([]);
  const [internalEntitesList, setInternalEntitiesList] = useState([]);
  const [externalEntitiesList, setExternalEntitiesList] = useState([]);
  const [entitiesList, setEntitiesList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [template, setTemplate] = useState<any>({
    id: 0,
    content: "",
  });
  const [contentLang, setContentLang] = useState(
    i18n.language === "en" ? "en" : "ar"
  );

  useEffect(() => {
    populateLists();
  }, []);

  const populateLists = async () => {
    setIsLoading(true);
    const p0 = getCorrespondenceList();
    const p1 = getCorrespondenceType();
    const p2 = getDomainTypes();
    const p3 = getImportanceLevels();
    const p4 = getPrivacyLevels();
    const p5 = getActiveUsers();
    const p6 = getDeliveryTypes();
    const p7 = getRoutingGroups();
    const p8 = getUserDetails();
    const p9 = getDocumentTemplates();
    const p10 = getEntity();

    Promise.all([
      p0.then((correspondence) => {
        setCorrespondenceList(correspondence);
      }),
      p1.then((correspondenceTypes) => {
        setCorrespondenceTypeList(correspondenceTypes);
      }),
      p2.then((domainTypes) => {
        setDomainTypeList(domainTypes);
      }),
      p3.then((importanceLevels) => {
        setImportanceLevelList(importanceLevels);
      }),
      p4.then((privacyLevels) => {
        setPrivacyLevelList(privacyLevels);
      }),
      p5.then((users) => {
        setUsersList(users);
      }),
      p6.then((deliveryTypes) => {
        setDeliveryList(deliveryTypes);
      }),
      p7.then((routingGroups) => {
        setRoutingGroups(routingGroups);
        setRoutingGroupList(
          routingGroups.map((res: any) => ({
            text: languageIsEn ? res.enName : res.arName,
            value: res.routingGroupId,
          }))
        );
      }),
      p8.then((userDetails: any) => {
        setCorrespondenceData({
          ...correspondenceData,
          RoutedBy: userDetails.userId,
        });
      }),
      p9.then((documentTemplates) => {
        setDocumentTemplateList(
          documentTemplates.filter((x: any) => x.documentTemplateTypeId === 1)
        );
        setDeliveryNoteList(
          documentTemplates
            .filter((x: any) => x.documentTemplateTypeId === 2)
            .map((res: any) => ({
              text: languageIsEn ? res.enName : res.arName,
              value: res.documentTemplateId,
            }))
        );
      }),
      p10.then((entities) => {
        setEntitiesList(entities);
        const dataForInternalEntitiesDropDown = entities
          .filter((res: any) => res.domainTypeId === 1)
          .map((res: any) => ({
            text: languageIsEn ? res.enName : res.arName,
            value: res.entityId,
          }));
        setInternalEntitiesList(dataForInternalEntitiesDropDown);
        const dataForExternalEntitiesDropDown = entities
          .filter((res: any) => res.domainTypeId === 2)
          .map((res: any) => ({
            text: languageIsEn ? res.enName : res.arName,
            value: res.entityId,
          }));
        setExternalEntitiesList(dataForExternalEntitiesDropDown);
      }),
    ])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const dataForFilteredRoutingGroups = routingGroups
      .filter((group: any) => group.entityId === correspondenceData.EntityId)
      .map((group: any) => {
        return {
          value: group.routingGroupId,
          text: languageIsEn ? group.enName : group.arName,
        };
      });
    setFilteredRoutingGroupsList(dataForFilteredRoutingGroups);
  }, [correspondenceData.EntityId]);

  const validateForm = (status: CorrespondenceStatus) => {
    const newErrors: any = {};

    if (correspondenceData.Subject.length === 0)
      newErrors["Subject"] = t("Subject is required");
    if (
      status === CorrespondenceStatus.Pending &&
      correspondenceData.RoutingGroupId === null
    )
      newErrors["route"] = t("Select a routing group");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async (status: CorrespondenceStatus) => {
    if (validateForm(status)) {
      setIsSaving(true);

      if (attachmentType && attachmentType === 1) {
        let blob = correspondenceData.MainAttachmentFile;
        if (blob && correspondenceData.PreviousCorrespondenceId) {
          const barcodeData = textToBase64Barcode(
            correspondenceData.PreviousCorrespondenceId
          );
          const fileBuffer =
            await correspondenceData.MainAttachmentFile.arrayBuffer();
          const fileWithBarcode = await addImageToPDF(
            fileBuffer,
            barcodeData,
            "png",
            { x: 10, y: 20, scale: 0.75 }
          );
          const fileWithText = await addTextToPDF(
            fileWithBarcode,
            "Previous Correspondence",
            { x: 50, y: 10 }
          );
          blob = new Blob([fileWithText], { type: "application/pdf" });
        }
        const data: any = {
          ...correspondenceData,
          Status: status,
          CorrespondenceDate: APIDateFormatter(
            correspondenceData.CorrespondenceDate
          ),
          DueDate:
            correspondenceData.DueDate !== null
              ? APIDateFormatter(correspondenceData.DueDate)
              : null,
          DateOfReference:
            correspondenceData.DateOfReference !== null
              ? APIDateFormatter(correspondenceData.DateOfReference)
              : null,
          ContentAsText: correspondenceData.ContentAsText,
          MainAttachmentFile: blob,
        };
        var form_data = new FormData();

        for (var key in data) {
          if (data[key] !== null) form_data.append(key, data[key]);
        }

        addCorrespondence(form_data)
          .then((response) => {
            setInfo(t("Correspondence created successfully"));
            props.handleClose(true);
          })
          .catch((err) => setError(err))
          .finally(() => setIsSaving(false));
      } else {
        const data: any = {
          ...correspondenceData,
          status: status,
          correspondenceDate: APIDateFormatter(
            correspondenceData.CorrespondenceDate
          ),
          dueDate:
            correspondenceData.DueDate !== null
              ? APIDateFormatter(correspondenceData.DueDate)
              : null,
          dateOfReference:
            correspondenceData.DateOfReference !== null
              ? APIDateFormatter(correspondenceData.DateOfReference)
              : null,
          contentAsText: correspondenceData.ContentAsText,
          contentDirection: contentLang === "en" ? "ltr" : "rtl",
        };

        addCorrespondenceWithContent(data)
          .then((response) => {
            setInfo(t("Correspondence created successfully"));
            props.handleClose(true);
          })
          .catch((err) => setError(err))
          .finally(() => setIsSaving(false));
      }
    }
  };

  const handleTemplateChange = (e: any) => {
    const { value } = e.target;
    if (value) {
      setIsChanged(true);
      setTemplate(value);
      setCorrespondenceData({
        ...correspondenceData,
        ContentAsText: value.content,
      });
    }
  };

  const handleDateChange = (value: any, name: any) => {
    if (name === "CorrespondenceDate") setDate(value);
    setIsChanged(true);
    setCorrespondenceData({
      ...correspondenceData,
      [name]: value,
    });
  };

  const onUpload = async (event: any) => {
    const file: File = event.target.files[0];
    if (fileTypeValidator(file)) {
      setExtractingText(true);
      getContentFromPdf(file)
        .then((content) => {
          setCorrespondenceData({
            ...correspondenceData,
            MainAttachmentFile: file,
            ContentAsText: content,
          });
          setFileName(file.name);
        })
        .catch((err) => setError(err))
        .finally(() => setExtractingText(false));
    }
  };

  const onScan = async (file: any) => {
    const fileData = new File([file], "scannedDoc.pdf", {
      type: "application/pdf",
    });

    setExtractingText(true);
    getContentFromPdf(fileData)
      .then((content) => {
        setCorrespondenceData({
          ...correspondenceData,
          MainAttachmentFile: fileData,
          ContentAsText: content,
        });
        setFileName("scannedDoc.pdf");
      })
      .catch((err) => setError(err))
      .finally(() => setExtractingText(false));
  };

  const onFileDelete = () => {
    setCorrespondenceData({
      ...correspondenceData,
      MainAttachmentFile: "",
    });
  };

  const contentChange = (newContent: string) => {
    setIsChanged(true);
    setCorrespondenceData({
      ...correspondenceData,
      ContentAsText: newContent,
    });
  };

  const fileTypeValidator = (file: File) => {
    //function to reject exe, dll, and system files
    if (
      file.type === "" ||
      file.type === "application/x-msdownload" ||
      file.type === "application/octet-stream"
    ) {
      setError(t("File type not accepted"));
      return false;
    } else return true;
  };

  const onClose = () => {
    if (isChanged) setOpen(true);
    else props.handleClose();
  };

  const showDeliveryNotes: boolean = routingGroups?.find(
    (x: any) => x.routingGroupId === correspondenceData.RoutingGroupId
  )?.requiresDeliveryNote;

  console.log({ showDeliveryNotes, correspondenceData });

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      fullWidth
      maxWidth={"xl"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("Create Correspondence")}
      </DialogTitle>
      <DialogContent>
        {open && (
          <ConfirmModal
            open={open}
            onYesClick={props.handleClose}
            onNoClick={() => setOpen(false)}
          />
        )}
        {openScan && (
          <ScanModal
            open={openScan}
            onClose={() => setOpenScan(false)}
            setFile={onScan}
          />
        )}
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Correspondence Type")}
              items={sortListAlphabetically(
                correspondenceTypeList.map((type: any) => {
                  return {
                    value: type.correspondenceTypeId,
                    text: languageIsEn ? type.enName : type.arName,
                  };
                })
              )}
              name="CorrespondenceTypeId"
              value={correspondenceData.CorrespondenceTypeId}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Previous Correspondence")}
              items={sortListAlphabetically(
                correspondenceList.map((item: any) => {
                  return {
                    value: item.correspondenceId,
                    text: `${item.correspondenceId} - ${item.subject}`,
                  };
                })
              )}
              name="PreviousCorrespondenceId"
              value={correspondenceData.PreviousCorrespondenceId}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <TextFieldDX
              label={t("Subject")}
              name="Subject"
              value={correspondenceData.Subject}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              error={errors["Subject"]}
            />
          </GridDX>
          <GridDX item xs={6}>
            <TextFieldDX
              label={t("Description")}
              name="Description"
              value={correspondenceData.Description}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <DatePickerDX
              label={t("Correspondence Date")}
              sx={{ flex: 1 }}
              name="CorrespondenceDate"
              value={moment(correspondenceData.CorrespondenceDate)}
              calendarId={calendarId}
              onChange={(value: any) => {
                handleDateChange(value, "CorrespondenceDate");
              }}
              disabled={true}
            />
          </GridDX>
          <GridDX item xs={6}>
            <DatePickerDX
              label={t("Due Date")}
              sx={{ flex: 1 }}
              name="DueDate"
              minDate={date}
              value={moment(correspondenceData.DueDate)}
              calendarId={calendarId}
              onChange={(value: any) => {
                handleDateChange(value, "DueDate");
              }}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Domain Type")}
              items={sortListAlphabetically(
                domainTypeList.map((type: any) => {
                  return {
                    value: type.domainTypeId,
                    text: languageIsEn ? type.enName : type.arName,
                  };
                })
              )}
              name="DomainTypeId"
              value={correspondenceData.DomainTypeId}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: {
                    ...correspondenceData,
                    EntityId: null,
                    EntityContactPerson: "",
                    ReferenceNumber: "",
                    DateOfReference: null,
                    RoutingGroupId: null,
                    DeliveryNoteTemplateId: null,
                  },
                });
              }}
              disabled={isLoading}
              // error={errors["DomainTypeId"]}
            />
          </GridDX>
          {correspondenceData.DomainTypeId && (
            <GridDX item xs={6}>
              <AutoCompleteListDX
                label={t("Entity")}
                items={sortListAlphabetically(
                  entitiesList
                    .filter(
                      (res: any) =>
                        res.domainTypeId === correspondenceData.DomainTypeId
                    )
                    .map((res: any) => ({
                      text: languageIsEn ? res.enName : res.arName,
                      value: res.entityId,
                    }))
                )}
                name="EntityId"
                value={correspondenceData.EntityId}
                onChange={(event: any) => {
                  handleInputChange({
                    event: event,
                    setIsChanged: setIsChanged,
                    setData: setCorrespondenceData,
                    data: {
                      ...correspondenceData,
                      RoutingGroupId: null,
                      DeliveryNoteTemplateId: null,
                    },
                  });
                }}
                disabled={isLoading}
                // error={}
              />
            </GridDX>
          )}
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Routing Group")}
              items={sortListAlphabetically(
                !correspondenceData.EntityId
                  ? routingGroupList
                  : filteredRoutingGroupsList
              )}
              name="RoutingGroupId"
              value={correspondenceData.RoutingGroupId}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: {
                    ...correspondenceData,
                    DeliveryNoteTemplateId: null,
                  },
                });
              }}
              disabled={isLoading}
              error={errors["route"]}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Routed By")}
              items={sortListAlphabetically(
                usersList.map((user: any) => {
                  return {
                    value: user.id,
                    text:
                      i18n.language === "en"
                        ? user.enFullName
                        : user.arFullName,
                  };
                })
              )}
              name="RoutedBy"
              value={correspondenceData.RoutedBy}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={true}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Importance Level")}
              items={sortListAlphabetically(
                importanceLevelList.map((level: any) => {
                  return {
                    value: level.importanceLevelId,
                    text: languageIsEn ? level.enName : level.arName,
                  };
                })
              )}
              name="ImportanceLevelId"
              value={correspondenceData.ImportanceLevelId}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Privacy Level")}
              items={sortListAlphabetically(
                privacyLevelList.map((level: any) => {
                  return {
                    value: level.privacyLevelId,
                    text: languageIsEn ? level.enName : level.arName,
                  };
                })
              )}
              name="PrivacyLevelId"
              value={correspondenceData.PrivacyLevelId}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <AutoCompleteListDX
              label={t("Delivery Method")}
              items={sortListAlphabetically(
                deliveryList.map((method: any) => {
                  return {
                    value: method.deliveryMethodId,
                    text: languageIsEn ? method.enName : method.arName,
                  };
                })
              )}
              name="DeliveryMethodId"
              value={correspondenceData.DeliveryMethodId}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          {correspondenceData?.DomainTypeId === 2 && (
            <GridDX item xs={6}>
              <TextFieldDX
                label={t("Entity Contact Person")}
                name="EntityContactPerson"
                value={correspondenceData.EntityContactPerson}
                onChange={(event: any) => {
                  handleInputChange({
                    event: event,
                    setIsChanged: setIsChanged,
                    setData: setCorrespondenceData,
                    data: correspondenceData,
                  });
                }}
                // error={}
              />
            </GridDX>
          )}
          {correspondenceData.DomainTypeId === 2 && (
            <>
              <GridDX item xs={6}>
                <TextFieldDX
                  label={t("Reference Number")}
                  name="ReferenceNumber"
                  value={correspondenceData.ReferenceNumber}
                  onChange={(event: any) => {
                    handleInputChange({
                      event: event,
                      setIsChanged: setIsChanged,
                      setData: setCorrespondenceData,
                      data: correspondenceData,
                    });
                  }}
                  // error={}
                />
              </GridDX>
              <GridDX item xs={6}>
                <DatePickerDX
                  label={t("Date of Reference")}
                  sx={{ flex: 1 }}
                  name="DateOfReference"
                  value={moment(correspondenceData.DateOfReference)}
                  calendarId={calendarId}
                  onChange={(value: any) => {
                    handleDateChange(value, "DateOfReference");
                  }}
                />
              </GridDX>
            </>
          )}
          {showDeliveryNotes && (
            <GridDX item xs={12} sm={6}>
              <AutoCompleteListDX
                label={t("Delivery Note Template")}
                items={deliveryNoteList}
                name="deliveryNoteTemplateId"
                value={correspondenceData.deliveryNoteTemplateId}
                onChange={(event: any) => {
                  handleInputChange({
                    event: event,
                    setIsChanged: setIsChanged,
                    setData: setCorrespondenceData,
                    data: correspondenceData,
                  });
                }}
                disabled={isLoading}
              />
            </GridDX>
          )}
          <GridDX item xs={12} sm={6}>
            <AutoCompleteListDX
              label={t("Attachment Type")}
              items={[
                { value: 1, text: t("Upload") + "/" + t("Scan") },
                { value: 2, text: t("Create Document") },
              ]}
              name="attachmentType"
              value={attachmentType}
              onChange={(event: any) => {
                console.log(event.target.value);
                setAttachmentType(event.target.value);
              }}
              error={errors["attachmentType"]}
            />
          </GridDX>
          {attachmentType && attachmentType === 1 ? (
            <>
              <GridDX xs={12} sm={6} pt={1}>
                <GridDX
                  component="fieldset"
                  sx={{
                    ml: 1,
                    mr: 0,
                    height: 65,
                    width: "100%",
                    border: "1px solid #c6c6c6",
                    borderRadius: 1,
                  }}
                >
                  <legend style={{ color: "#808080", fontSize: 12 }}>
                    {t("Main Attachment")}
                  </legend>
                  {correspondenceData.MainAttachmentFile !== "" ? (
                    <Chip
                      label={fileName}
                      color="primary"
                      onDelete={onFileDelete}
                    />
                  ) : (
                    <>
                      <LoadingButtonDX
                        variant="contained"
                        component="label"
                        sx={{ height: 32 }}
                        loading={extractingText}
                      >
                        {t("Upload")}
                        <input type="file" hidden onChange={onUpload} />
                      </LoadingButtonDX>
                      <LoadingButtonDX
                        sx={{ mx: 1, height: 32 }}
                        onClick={() => setOpenScan(true)}
                        loading={extractingText}
                      >
                        {t("Scan")}
                      </LoadingButtonDX>
                    </>
                  )}
                </GridDX>
              </GridDX>
              <GridDX item xs={12}>
                <CheckBoxDX
                  name="applyWatermark"
                  label={t("Apply Watermark")}
                  checked={correspondenceData.applyWatermark}
                  onChange={(event: any) => {
                    handleInputChange({
                      event: event,
                      setIsChanged: setIsChanged,
                      setData: setCorrespondenceData,
                      data: correspondenceData,
                    });
                  }}
                />
              </GridDX>
            </>
          ) : (
            attachmentType && (
              <>
                <GridDX item xs={6}>
                  <AutoCompleteListDX
                    label={t("Document Template")}
                    items={sortListAlphabetically(
                      documentTemplateList.map((template: any) => {
                        return {
                          value: template,
                          text: languageIsEn
                            ? template.enName
                            : template.arName,
                        };
                      })
                    )}
                    name="documentTemplateId"
                    value={template}
                    onChange={handleTemplateChange}
                    // error={}
                  />
                </GridDX>
                <GridDX item xs={12}>
                  <CheckBoxDX
                    name="applyWatermark"
                    label={t("Apply Watermark")}
                    checked={correspondenceData.applyWatermark}
                    onChange={(event: any) => {
                      handleInputChange({
                        event: event,
                        setIsChanged: setIsChanged,
                        setData: setCorrespondenceData,
                        data: correspondenceData,
                      });
                    }}
                  />
                </GridDX>
                <GridDX item xs={12} justifyContent="center">
                  <CKEditorDX
                    label={t("Template")}
                    langSelectorLabe={"Language"}
                    contentLang={contentLang}
                    setContentLang={setContentLang}
                    data={correspondenceData?.ContentAsText}
                    onChange={contentChange}
                  />
                </GridDX>
              </>
            )
          )}
          <GridDX item xs={12}>
            <TextFieldDX
              label={t("Notes")}
              fullWidth
              multiline
              name="Notes"
              rows={3}
              value={correspondenceData.Notes}
              onChange={(event: any) => {
                handleInputChange({
                  event: event,
                  setIsChanged: setIsChanged,
                  setData: setCorrespondenceData,
                  data: correspondenceData,
                });
              }}
              // error={}
            />
          </GridDX>
        </GridDX>
      </DialogContent>
      <DialogActions>
        <GridDX
          container
          width={"100%"}
          sx={{ justifyContent: "space-around" }}
        >
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <ButtonDX
              variant="outlined"
              onClick={onClose}
              sx={{ mb: { xs: 2, sm: "auto" } }}
            >
              {t("Cancel")}
            </ButtonDX>
          </GridDX>
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <LoadingButtonDX
              onClick={() => onSave(CorrespondenceStatus.Draft)}
              loading={isSaving}
              // loadingPosition="end"
            >
              {t("Save as Draft")}
            </LoadingButtonDX>
          </GridDX>
          <GridDX xs={12} md={4} mt={1} justifyContent="center">
            <LoadingButtonDX
              onClick={() => onSave(CorrespondenceStatus.Pending)}
              loading={isSaving}
              // loadingPosition="end"
            >
              {t("Save and Send")}
            </LoadingButtonDX>
          </GridDX>
        </GridDX>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCorrespondenceModal;
